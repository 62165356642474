// Componant: Button

.btn {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  min-width: 100px;
  text-transform: unset;
  @media screen and (max-width: 1399px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media screen and (max-width: 767px) {
    min-width: unset;
  }
  &[disabled] {
    cursor: not-allowed;
  }
  &.btn-lg {
    padding: 17px 24px;
    height: 52px;
    i {
      font-size: 20px;
      margin-top: -2px;
    }
  }
  &.btn-md {
    padding: 14px 24px;
    height: 44px;
    @media screen and (max-width: 991px) {
      padding: 12px 16px;
      height: 40px;
    }
    @media screen and (max-width: 575px) {
      padding: 14px 24px;
      height: 60px;
    }
    i {
      font-size: 20px;
      margin-top: -2px;
    }
  }
  &.btn-sm {
    padding: 11px 24px;
    height: 38px;
  }
  &.btn-icon-only {
    padding: 11px 16px;
    min-width: unset;
  }
}

.btn-primary {
  background-color: $dark-purple;
  color: $white !important;
  border-color: $dark-purple;
  &:hover,
  &:focus {
    background-color: $purple-1;
    color: $white !important;
    border-color: $purple-1;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $purple-1;
    color: $white !important;
    border-color: $purple-1;
  }
}

.btn-purple {
  background-color: $purple-2;
  color: $white !important;
  border-color: $purple-2;
  &:hover,
  &:focus {
    background-color:$purple-4;
    color: $dark-purple !important;
    border-color:$purple-4;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color:$purple-4;
    color: $dark-purple !important;
    border-color:$purple-4;
  }
}

.btn-light-purple {
  background-color: $purple-1;
  color: $white !important;
  border-color: $purple-1;
  &:hover,
  &:focus {
    background-color: $purple-3;
    color: $dark-purple !important;
    border-color:$purple-3;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color:$purple-3;
    color: $dark-purple !important;
    border-color:$purple-3;
  }
}

.btn-blue {
  background-color: $blue;
  color: $white !important;
  border-color: $blue;
  &:hover,
  &:focus {
    background-color: $blue;
    color: $white !important;
    border-color: $blue;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $blue;
    color: $white !important;
    border-color: $blue;
  }
}

.btn-purple-2 {
  background-color: $purple-2-100;
  color: $white !important;
  border-color: $purple-2-100;
  &:hover,
  &:focus {
    background-color: $purple-2-100;
    color: $white !important;
    border-color: $purple-2-100;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $purple-2-100;
    color: $white !important;
    border-color: $purple-2-100;
  }
}

.card-btn-purple {
  background-color: $purple;
  color: $white !important;
  border-color: $purple;
  &:hover,
  &:focus {
    background-color: $purple;
    color: $white !important;
    border-color: $purple;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $purple;
    color: $white !important;
    border-color: $purple;
  }
}

.card-btn-purple-medium {
  background-color: $purple-400;
  color: $white !important;
  border-color: $purple-400;
  &:hover,
  &:focus {
    background-color: $purple-400;
    color: $white !important;
    border-color: $purple-400;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $purple-400;
    color: $white !important;
    border-color: $purple-400;
  }
}


.btn-secondary {
  background-color: $white;
  color: $dark-purple !important;
  border-color: $dark-purple;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: $dark-purple;
    color: $white !important;
    border-color: $dark-purple;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $dark-purple;
    color: $white !important;
    border-color: $dark-purple;
  }
  &[disabled] {
    background-color: transparentize($gray-100, 0.7);
    border-color: transparentize($gray-100, 0.7);
    color: transparentize($black, 0.74) !important;
  }
}

.btn-outline-purple-1 {
  background-color: $white;
  color: $purple-1 !important;
  border-color: $purple-1;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: $purple-1;
    color: $white !important;
    border-color: $purple-1;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $purple-1;
    color: $white !important;
    border-color: $purple-1;
  }
  &[disabled] {
    background-color: transparentize($gray-100, 0.7);
    border-color: transparentize($gray-100, 0.7);
    color: transparentize($black, 0.74) !important;
  }
}

.btn-outline-danger {
  background-color: $white;
  color: $red-100 !important;
  border-color: $red-100;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: $red-100;
    color: $white !important;
    border-color: $red-100;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: $red-100;
    color: $white !important;
    border-color: $red-100;
  }
  &[disabled] {
    background-color: transparentize($red-100, 0.7);
    border-color: transparentize($red-100, 0.7);
    color: transparentize($white, 0.74) !important;
  }
}

.btn-link {
  background-color: transparent;
  color: $dark-purple-400 !important;
  border-color: transparent;
  &:hover,
  &:focus {
    background-color: transparent;
    color: $purple-2 !important;
    border-color: transparent;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: transparent;
    color: $purple-2 !important;
    border-color: transparent;
  }
  &[disabled] {
    color: $dark-purple-100;
  }
}

.btn-link-purple {
  background-color: transparent;
  color: $purple-2 !important;
  border-color: transparent;
  min-width: 75px !important;
  &:hover,
  &:focus {
    background-color: transparent;
    color: $dark-purple !important;
    border-color: transparent;
  }
  &:not([disabled]):not(.disabled):not(.btn-link):hover,
  &:not([disabled]):not(.disabled):not(.btn-link):focus {
    box-shadow: none;
    background-color: transparent;
    color: $dark-purple !important;
    border-color: transparent;
  }
  &[disabled] {
    color: $dark-purple-100;
  }
}
