// Component - Modal

.custom-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    display: none;
  }

  .MuiModal-backdrop {
    background-color: transparentize($gray-200, 0.6) !important;
  }

  .MuiPaper-root {
    margin: 16px;
    border-radius: 16px;
    background-color: $white;
    box-shadow: 0px 0px 32px -1px transparentize($dark-purple-500, 0.85);
    max-width: 520px;
    min-width: 520px;
    width: 100%;
    padding: 24px;
    position: relative;
    outline: none;

    @media screen and (max-width: 1399px) {
      padding: 20px;
      border-radius: 12px;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
      border-radius: 8px;
    }

    @media screen and (max-width: 575px) {
      min-width: unset;
    }

    .custom-dialog-title {
      column-gap: 16px;
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      color: $dark-purple;
      margin-bottom: 32px;
      padding-right: 30px;

      @media screen and (max-width: 1399px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 28px;
        padding-right: 26px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 24px;
        padding-right: 22px;
      }
    }

    .dialog-close {
      min-width: unset;
      font-size: 16px;
      color: $dark-purple-100;
      background-color: transparent;
      position: absolute;
      right: 30px;
      top: 25px;

      @media screen and (max-width: 1399px) {
        font-size: 14px;
        right: 26px;
        top: 21px;
      }

      @media screen and (max-width: 991px) {
        font-size: 12px;
        right: 22px;
        top: 17px;
      }
      @media screen and (max-width: 768px) {
        right: 8px;
        top: 10px;
      }
    }

    .org-content {
      column-gap: 16px;

      .org-image {
        padding-bottom: 10px;
        width: 80px;
        min-height: 69px;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 991px) {
          width: 70px;
          height: 60px;
        }

        &::before {
          content: "";
          width: 80px;
          height: 100%;
          background-image: url(../../images/vector-3.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: -1;

          @media screen and (max-width: 991px) {
            width: 70px;
          }
        }

        img {
          width: 64px;
          height: 32px;
          object-fit: contain;

          @media screen and (max-width: 991px) {
            width: 58px;
            height: 28px;
          }
        }
      }

      .org-name {
        p {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: $green-100;
          margin-top: 12px;
          margin-bottom: 12px;

          @media screen and (max-width: 1399px) {
            margin-bottom: 10px;
            margin-top: 10px;
          }

          @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 8px;
            margin-top: 8px;
          }
        }

        h5 {
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          color: $dark-purple-400;

          @media screen and (max-width: 1399px) {
            font-size: 14px;
            line-height: 18px;
          }

          @media screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .action-btns {
      margin: 0px -24px;
      padding: 16px 24px 0px;

      @media screen and (max-width: 1399px) {
        margin: 0px -20px;
        padding: 14px 20px 0;
      }

      @media screen and (max-width: 767px) {
        margin: 0px -16px;
        padding: 12px 16px 0;
      }
    }
  }

  &.custom-dialog-lg {
    .MuiPaper-root {
      max-width: 700px;
    }
  }

  &.investment-advice-dialog {
    .MuiPaper-root {
      max-width: 640px;
      padding: 24px 44px 70px;

      @media screen and (max-width: 1399px) {
        padding: 20px 40px 60px;
      }

      @media screen and (max-width: 767px) {
        padding: 16px 36px 50px;
      }

      .investment-advice-content {
        .org-image {
          width: 216px;
          height: 186px;
          padding-bottom: 30px;
          padding-left: 20px;
          margin-bottom: 30px;

          @media screen and (max-width: 1399px) {
            width: 190px;
            height: 180px;
            margin-bottom: 26px;
          }

          @media screen and (max-width: 767px) {
            width: 160px;
            height: 152px;
            padding-bottom: 15px;
            margin-bottom: 22px;
          }

          @media screen and (max-width: 575px) {
            width: 130px;
            height: 123px;
            padding-left: 10px;
            margin-bottom: 18px;
          }

          &::before {
            width: 216px;

            @media screen and (max-width: 1399px) {
              width: 190px;
            }

            @media screen and (max-width: 767px) {
              width: 160px;
            }

            @media screen and (max-width: 575px) {
              width: 130px;
            }
          }

          img {
            width: 110px;
            height: 116px;

            @media screen and (max-width: 1399px) {
              width: 90px;
              height: 96px;
            }

            @media screen and (max-width: 767px) {
              width: 70px;
              height: 74px;
            }

            @media screen and (max-width: 575px) {
              width: 50px;
              height: 53px;
            }
          }
        }

        .investment-advice-info {
          p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: $gray-600;
            margin-bottom: 20px;
            text-align: center;

            @media screen and (max-width: 1399px) {
              font-size: 14px;
              line-height: 18px;
              margin-bottom: 16px;
            }

            @media screen and (max-width: 575px) {
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 12px;
            }
          }

          .btn {
            margin-top: 44px;

            @media screen and (max-width: 1399px) {
              margin-top: 36px;
            }

            @media screen and (max-width: 575px) {
              margin-top: 28px;
            }
          }
        }
      }
    }
  }

  &.select-role-dialog {
    .MuiModal-backdrop {
      background-color: transparent;
    }

    .MuiPaper-root {
      box-shadow: none;
    }
  }

  .modalmessage-consent-txt {
    color: $dark-purple;
    text-align: left;

    img {
      width: 100%;
      display: block;
      height: auto;
    }
  }

  .modalmessage-txt {
    color: $dark-purple;
    text-align: center;
  }

  .mesgicon {
    .anticon {
      font-size: 40px;
    }
  }

  .logo-avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    width: 72px;
    height: 72px;
    font-size: 1.75rem;

    &.success {
      color: $green;
      background-color: rgba(51, 172, 150, 0.1);
    }

    &.error {
      color: $red;
      background-color: rgba(227, 45, 45, 0.1);
    }

    &.logout {
      color: $purple-1;
      background-color: rgba(57, 20, 116, 0.1);
    }
  }

  &#snoop-confirm-dialog {
    .MuiPaper-root {
      min-width: 350px;
      max-width: 350px;
    }
  }

  &#snoop-dialog {
    .MuiPaper-root {
      max-width: 450px;
      min-width: 450px;
      padding: 20px 20px 40px !important;

      @media (max-width: 768px) {
        min-width: 320px;
      }

      @media (max-width: 360px) {
        min-width: 300px;
      }
    }

    .org-content {
      @media (max-width: 768px) {
        height: 200px;
        justify-content: center;
      }
    }

    .snoop-qrcode {
      position: relative;
      padding: 25px;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: no-repeat top url("../../images/top-corner.svg");
        background-size: contain;
        right: 0;
        left: 0;
        top: 0;
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: no-repeat bottom url("../../images/bottom-corner.svg");
        background-size: contain;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }

  &.score-dialog {
    bottom: unset;
    padding: 16px;
    overflow-y: auto;
    height: 100%;
    display: block;
    width: 100%;

    .MuiDialog-container {
      height: unset;
      min-height: 100%;
    }

    .MuiPaper-root {
      max-height: unset;
      margin: 0 auto;
    }
  }
}

.cancle-booking_content {
  margin: 40px 0;

  .cancle-booking_image {
    position: relative;
    width: 138px;
    height: 103px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 12px;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-image: url(../../images/blob.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
    }

    img {
      width: 74px;
      height: 75px;
      object-fit: contain;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: $dark-purple-600;
    margin-bottom: 0;

    @media screen and (max-width: 1399px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (max-width: 575px) {
      padding: 0 24px;
    }

    br {
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
  }

  &.scrore_content {
    margin-bottom: 0px;

    h3 {
      color: $dark-purple;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: $dark-purple;
      max-width: 450px;
      margin: 0 auto;
    }

    .scrore-details {
      padding-bottom: 12px;
      border-bottom: 1px dashed $gray-100;
      column-gap: 20px;

      &:last-child {
        border-bottom: 0px;
      }

      &_wrapper {
        row-gap: 12px;
        padding-right: 8px;
        max-height: 210px;
        overflow-y: auto;

        @media screen and (max-width: 767px) {
          max-height: 200px;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: $dark-purple-100;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $dark-purple-500;
          border-radius: 5px;
        }
      }

      h4,
      p {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        color: $dark-purple-800;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      p {
        color: $purple-2;
        margin-bottom: -3px;
      }
    }
  }
}

.scrore-details_right {
  column-gap: 20px;

  .star-rating {
    column-gap: 4px;

    @media screen and (max-width: 767px) {
      column-gap: 2px;
    }

    .MuiRating-icon {
      color: $dark-purple-100;
      font-size: 18px;

      &.MuiRating-iconFilled {
        color: $purple-2;
      }

      @media screen and (max-width: 1399px) {
        font-size: 16px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }

      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
    }

    .MuiRating-label {
      display: none;
    }
  }
}

.booking-confirmation_content {
  .booking-confirmation_image {
    position: relative;
    width: 138px;
    height: 112px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 12px;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-image: url(../../images/blob.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
    }

    img {
      width: 95px;
      height: 112px;
      object-fit: contain;
    }
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: $dark-purple-600;

    @media screen and (max-width: 575px) {
      font-size: 12px;
      line-height: 16px;
    }

    &.note {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 16px;
    }
  }

  ul {
    padding: 12px 0;
    column-gap: 16px;

    li {
      width: max-content;
      column-gap: 6px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      color: $purple-200;

      @media screen and (max-width: 575px) {
        font-size: 16px;
        line-height: 19px;
      }

      i {
        font-size: 20px;
        line-height: 20px;

        @media screen and (max-width: 575px) {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }

  .custom-form {
    &_group {
      margin-bottom: 10px;
    }

    &_control {
      margin: 0;
      margin-right: 20px;
      align-items: start;
      column-gap: 8px;

      @media screen and (max-width: 575px) {
        margin-right: 0;
      }

      .MuiCheckbox-root {
        padding: 0;
      }

      .MuiFormControlLabel-label {
        font-size: 12px !important;
        line-height: 16px;
        font-weight: 400;
        color: $dark-purple-400;
      }
    }
  }
}

.contribution-calculator-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 9rem;
  margin-bottom: 3rem;

  .contact-us {
    width: 33%;
  }

  .amount {
    color: $purple-v2;
    font-size: 1.375rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }
}

.calculator-btn {
  background: $purple !important;
}

.calculater-heading {
  color: $purple-v2 !important;
}

.contribution-modal-background {
  .contribution-calculator-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 1rem;
    text-align: center;
    max-width: 43.3125rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: $sm-breakpoint) {
      width: 90%;
      max-height: 90vh; 
      overflow: hidden;
      .calculator-form { 
        flex: 1;
        overflow-y: auto;
        padding-bottom: 1rem;
      }
    }

    .contact-us {
      width: auto;
    }
  }
}

.modal-background {
  background-color: rgba($color: #000000, $alpha: 0.57);
}

.sub-heading {
  color: $purple-v2 !important;
  font-size: $font-size-lg !important;
  font-weight: $font-weight-bold !important;
  // line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-base !important;
  }
}

.custom-input .MuiInputBase-root .MuiOutlinedInput-input {
  padding: 25px 0px;
  height: 2rem;
}

.modal-masked-input .MuiInputBase-root .MuiOutlinedInput-input {
  padding: 25px 20px;
}
.modal-date-input .MuiInputBase-root .MuiOutlinedInput-input {
  padding: 28px 20px;
}

.contained-btn {
  color: $white !important;
  background-color: $linear-background !important;
  border-radius: 0.5rem !important;
}

.largebtn {
  width: 18.0938rem !important;

  @media screen and (max-width: $md-breakpoint) {
    max-width: 16rem;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 15rem;
    font-size: 0.7rem !important;
  }
}

.outlined-btn {
  color: $primary !important;
  background: $white;
  border: 1px solid $linear-background !important;
}

.primary-clr-btn {
  text-transform: none !important;
  width: 17rem !important;
  color: $white !important;
  background-color: $purple !important;

  @media screen and (max-width: $lg-breakpoint) {
    max-width: 16.0938rem !important;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $md-breakpoint) {
    max-width: 17rem !important;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 15rem !important;
    font-size: 0.8rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    max-width: 13rem !important;
    font-size: 0.7rem !important;
  }
}

.service-button {
  width: 17.8125rem !important;
  height: 50px !important;
  background-color: $primary !important;
  color: $white !important;
  text-transform: capitalize !important;
  border-radius: 0.5rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 15.8125rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    width: 14rem !important;
    font-size: 0.9rem !important;
  }
}

.service-border {
  border: 1px solid #151a44 !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 11.8125rem !important;
    font-size: 0.9rem !important;
  }
}

.confirmation-warning {
  background-color: $warn-red !important;
  border: 1px solid $warn-border !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 11.8125rem !important;
    font-size: 0.9rem !important;
  }
}

.thinbtn {
  width: 12rem !important;
  border-radius: 0.5rem !important;
  height: 2.8125rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 9rem !important;
    font-size: 0.8rem !important;
  }
}

.large-service-button {
  width: 20.8125rem !important;
  @media screen and (max-width: $sm-breakpoint) {
    width: 15.8125rem !important;
  }
}

.event-confirmation-dialog {
  .MuiPaper-root {
    width: 60.5rem !important;
    border-radius: 1rem;
  }

  .dialog-header {
    padding: 0 !important;
    background-color: $primary;
    text-align: center;
    height: 4.25rem;

    .dialog-heading {
      font-weight: bold;
      color: $white;
      font-size: 1.0625rem !important;
      padding: 1.5rem 2.5rem;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.925rem !important;
      }
    }
  }

  .cancel-header {
    background-color: $purple-v2;
  }

  .cancelbtn {
    color: #ffffff !important;
    background-color: $purple-v2;

    &:hover {
      background-color: $purple-v2;
    }
  }

  .detail-heading-box {
    text-align: center;
    padding: 1rem 4.5rem;

    @media screen and (max-width: $xsm-breakpoint) {
      padding: 1rem 2.5rem !important;
    }
  }

  .text-left {
    text-align: left;
  }
}

.disable-text-transform {
  text-transform: none !important;
}

.custom-dialog-backdrop {
  .MuiModal-backdrop {
    background-color: rgba(57, 20, 116, 0.8) !important;
  }
}
